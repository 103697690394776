/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Grid, Typography } from '@material-ui/core';

import Header from "./header"
import "./layout.css"

import { Container } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import { BackTop } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import { ArrowUpOutlined } from '@ant-design/icons';

const style = {
  height: 40,
  width: 40,
  // lineHeight: '40px',
  borderRadius: 4,
  backgroundColor: '#000',
  color: '#fff',
  textAlign: 'center',
  fontSize: 20,
};

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
            backgroundColor:'#000',
            color:'#fff',
            padding:15,
          }}
        >
              <Container>
               <Grid container>
            <Grid item xs={12} md={8}>
            <Typography variant="body2">
              Copyright © {new Date().getFullYear()}, Dr.B.R AMBEDKAR COLLEGE OF LAW. All Rights Reserved. {` `}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" style={{ textAlign:'right' }}>
              Design &amp; Development by <a href="https://www.srkinfosystems.com/" rel="noopener noreferrer" target="_blank" className="text-warning">SRK Infosystems</a>
              </Typography>
            </Grid>
          </Grid>
          </Container>
        </footer>
        <BackTop>
      <div style={style}><ArrowUpOutlined /></div>
    </BackTop>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
