import React from 'react';
import { Link } from "gatsby";
import { Menu } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

// const { SubMenu } = Menu;

export default class Antmobilemenu extends React.Component {
  handleClick = e => {
    console.log('click ', e);
  };

  render() {
    return (
      <div className="mobilemenu">
      <Menu
        onClick={this.handleClick}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="dark"
        // style={{ backgroundColor:'#252121', borderRight:'none'}}
      >
          <Menu.Item to="/">
          <Link to="/"> Home </Link>
        </Menu.Item>
        <Menu.Item to="/about/">
          <Link to="/about/"> About College </Link>
        </Menu.Item>
        <Menu.Item to="/courses-offered/">
          <Link to="/courses-offered/"> Courses Offered </Link>
        </Menu.Item>
        <Menu.Item to="/admission-details/">
          <Link to="/admission-details/"> Admission Details </Link>
        </Menu.Item>
        <Menu.Item to="/facilities/">
          <Link to="/facilities/"> Facilities </Link>
        </Menu.Item>
        <Menu.Item to="/">
          <Link to="/gallery/"> Gallery </Link>
        </Menu.Item>
        <Menu.Item to="/payonline/">
          <Link to="/payonline/"> Pay Online </Link>
        </Menu.Item>
        <Menu.Item to="/contact/">
          <Link to="/contact/"> Contact </Link>
        </Menu.Item>

        
       
      </Menu>
      </div>
    );
  }
}