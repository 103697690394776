import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Myheader from "./myheader"
import { Hidden, Typography } from '@material-ui/core';

import { StaticImage } from "gatsby-plugin-image"

import { Container, Navbar, Nav, Row, Col, NavItem } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

const Header = ({ siteTitle }) => {
  
  
  return(
  <header>
  <Hidden mdUp>
      <Myheader/>
      </Hidden>
      <Hidden smDown>
      <Container>
        <Row className="pt-2">
          <Col lg="2" className="d-none d-lg-block">
            <Link to="/">
          <StaticImage
      src="../images/logo.png"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Dr.BR.Ambedkar College of Law"
      layout="fixed"
      style={{ position:'relative', left:'-40px', zIndex:9 }}
    />
    </Link>
          </Col>

          <Col lg="10">
          <div className="law">
                      <Typography variant="h4" component="h1" align="center" className="text-white mb-2 nowarp">DR.B.R.AMBEDKAR COLLEGE OF LAW</Typography>
                      <Typography variant="h6" className="nowarp" align="center">Affiliated to Karnataka State Law University, Recognized by Government of Karnataka</Typography>
                      <Typography variant="h5" className="text-center pb-3 pt-2">Approved By Bar Council of India</Typography>
                      </div>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" style={{ zIndex:99 }}>

  {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
    <NavItem>
        <Link to="/" activeClassName="active">Home</Link>
      </NavItem>
      <NavItem>
        <Link to="/about/" activeClassName="active">About College</Link>
      </NavItem>
      <NavItem>
        <Link to="/courses-offered/" activeClassName="active">Courses Offered</Link>
      </NavItem>
      <NavItem>
        <Link to="/admission-details/" activeClassName="active">Admission Details</Link>
      </NavItem>
      <NavItem>
        <Link to="/facilities/" activeClassName="active">Facilities</Link>
      </NavItem>
      <NavItem>
        <Link to="/gallery/" activeClassName="active">Gallery</Link>
      </NavItem>
      <NavItem>
        <Link to="/payonline/" activeClassName="active">Pay Online</Link>
      </NavItem>
      <NavItem>
        <Link to="/contact/" activeClassName="active">Contact Us</Link>
      </NavItem>
    </Nav>
  </Navbar.Collapse>

</Navbar>
            </Col>

        </Row>
        </Container>

    
</Hidden>

  </header>
)
  }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
